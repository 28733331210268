import React, {
  CSSProperties,
  FC,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { useResizeDetector } from 'react-resize-detector';
import { ArrowLeftShadyIcon, ArrowRightIcon } from '@wix/wix-vod-shared/icons';
import styles from './CssSlider.scss';
import classNames from 'classnames';

type Accessibility = {
  containerMessage?: string;
  prevSlideMessage?: string;
  nextSlideMessage?: string;
};

type Props = {
  itemsGap?: number;
  isRTL: boolean;
  items: string[];
  itemsCount: number;
  renderItem(item: string): ReactNode;
  loadMore(count: number): void;
  a11y?: Accessibility;
  navButtonClassName?: string;
  navButtonStyle?: CSSProperties;
  navButtonsAbsolute?: boolean;
  showArrowsOnHover?: boolean;
  dataHook?: string;
};

const useNextItemIndex = ({
  children,
  scrollLeft,
  containerWidth,
  isRTL,
}: {
  children: HTMLDivElement[];
  scrollLeft: number;
  containerWidth: number;
  isRTL: boolean;
}): number => {
  const scrolled = Math.abs(scrollLeft);

  for (let i = 0; i < children.length; i++) {
    const { offsetLeft, clientWidth } = children[i];
    const offsetRight = containerWidth - offsetLeft - clientWidth;
    const itemStartOffset = isRTL ? offsetRight : offsetLeft;
    const itemEndOffset = itemStartOffset + clientWidth;
    const croppedOrNotVisible = itemEndOffset > scrolled + containerWidth;
    const isFirst = itemStartOffset === scrolled;

    if (croppedOrNotVisible && !isFirst) {
      return i;
    }
  }

  return -1;
};

const usePrevItemIndex = ({
  children,
  scrollLeft,
  containerWidth,
  isRTL,
  visibleCount,
}: {
  children: HTMLDivElement[];
  scrollLeft: number;
  containerWidth: number;
  isRTL: boolean;
  visibleCount: number;
}): number => {
  const scrolled = Math.abs(scrollLeft);

  if (scrolled === 0) {
    return -1;
  }

  for (let i = 0; i < children.length; i++) {
    const { offsetLeft, clientWidth } = children[i];
    const offsetRight = containerWidth - offsetLeft - clientWidth;
    const itemStartOffset = isRTL ? offsetRight : offsetLeft;
    const itemEndOffset = itemStartOffset + clientWidth;

    if (itemEndOffset > scrolled) {
      return Math.max(i - visibleCount, 0);
    }
  }

  return 0;
};

const useVisibleCount = ({
  children,
  scrollLeft,
  containerWidth,
  isRTL,
}: {
  children: HTMLDivElement[];
  scrollLeft: number;
  containerWidth: number;
  isRTL: boolean;
}): number => {
  const scrolled = Math.abs(scrollLeft);
  let firstIndex = -1;

  for (let i = 0; i < children.length; i++) {
    const { offsetLeft, clientWidth } = children[i];
    const offsetRight = containerWidth - offsetLeft - clientWidth;
    const itemStartOffset = isRTL ? offsetRight : offsetLeft;
    const itemEndOffset = itemStartOffset + clientWidth;

    if (firstIndex === -1 && itemEndOffset > scrolled) {
      firstIndex = i;
    }

    if (itemEndOffset >= scrolled + containerWidth) {
      return i - firstIndex + 1;
    }
  }

  return 0;
};

const useChildren = (container: HTMLDivElement | null): HTMLDivElement[] => {
  if (!container) {
    return [];
  }

  const { children } = container;
  return [...children] as HTMLDivElement[];
};

const NavButton: FC<{
  onClick(): void;
  className?: string;
  direction: 'left' | 'right';
  hidden: boolean;
  absolute?: boolean;
  ariaLabel?: string;
  showOnHover?: boolean;
  dataHook?: string;
  style?: CSSProperties;
}> = ({
  onClick,
  className,
  direction,
  hidden,
  absolute,
  ariaLabel,
  showOnHover,
  dataHook,
  style,
}) => {
  return (
    <button
      aria-label={ariaLabel}
      data-hook={dataHook}
      className={classNames(styles.navButton, className, {
        [styles.hidden]: hidden,
        [styles.absolute]: absolute,
        [styles.showOnHover]: showOnHover,
        [styles.arrowLeft]: direction === 'left',
        [styles.arrowRight]: direction === 'right',
      })}
      style={style}
      onClick={onClick}
    >
      {absolute ? (
        <ArrowLeftShadyIcon size="30px" />
      ) : (
        <ArrowRightIcon size="30px" />
      )}
    </button>
  );
};

export const CssSlider: FC<Props> = ({
  itemsGap = 0,
  isRTL,
  items,
  itemsCount,
  renderItem,
  loadMore,
  a11y,
  navButtonStyle,
  navButtonClassName,
  navButtonsAbsolute,
  showArrowsOnHover,
  dataHook,
}) => {
  const { width: containerWidth = 0, ref } = useResizeDetector<HTMLDivElement>({
    refreshMode: 'debounce',
    refreshRate: 300,
  });
  const container = ref.current;
  const children = useChildren(container);
  const [scrollLeft, setScrollLeft] = useState(0);
  const visibleCount = useVisibleCount({
    children,
    containerWidth,
    isRTL,
    scrollLeft,
  });
  const nextItemIndex = useNextItemIndex({
    children,
    containerWidth,
    scrollLeft,
    isRTL,
  });

  const prevItemIndex = usePrevItemIndex({
    children,
    scrollLeft,
    containerWidth,
    isRTL,
    visibleCount,
  });

  const handleScroll = debounce((value: number) => {
    setScrollLeft(value);
  }, 300);

  const scrollToItem = (index: number) => {
    if (!container) {
      return;
    }

    const item = container.children[index] as HTMLDivElement | undefined;

    if (item) {
      const { offsetLeft, clientWidth } = item;

      if (isRTL) {
        const offsetRight = containerWidth - offsetLeft - clientWidth;
        container.scrollLeft = -offsetRight;
      } else {
        container.scrollLeft = offsetLeft;
      }
    }
  };

  const handleNextClick = () => scrollToItem(nextItemIndex);
  const handlePrevClick = () => scrollToItem(prevItemIndex);

  useEffect(() => {
    const loadedCount = items.length;

    if (loadedCount === itemsCount) {
      return;
    }

    if (loadedCount - nextItemIndex < visibleCount) {
      loadMore(visibleCount);
    }
  }, [items, itemsCount, loadMore, nextItemIndex, visibleCount]);

  return (
    <div className={styles.root} data-hook={dataHook}>
      <NavButton
        dataHook="css-slider-prev-button"
        onClick={handlePrevClick}
        style={navButtonStyle}
        absolute={navButtonsAbsolute}
        className={navButtonClassName}
        direction={isRTL ? 'right' : 'left'}
        hidden={scrollLeft === 0}
        ariaLabel={a11y?.prevSlideMessage}
        showOnHover={showArrowsOnHover}
      />
      <div
        data-hook="css-slider-slides"
        aria-label={a11y?.containerMessage}
        className={styles.slides}
        style={{ gap: itemsGap }}
        ref={ref}
        onScroll={(event) => handleScroll(event.currentTarget.scrollLeft)}
      >
        {items.map((item) => (
          <div key={item}>{renderItem(item)}</div>
        ))}
      </div>
      <NavButton
        dataHook="css-slider-next-button"
        style={navButtonStyle}
        onClick={handleNextClick}
        absolute={navButtonsAbsolute}
        className={navButtonClassName}
        direction={isRTL ? 'left' : 'right'}
        hidden={nextItemIndex === -1}
        ariaLabel={a11y?.nextSlideMessage}
        showOnHover={showArrowsOnHover}
      />
    </div>
  );
};
